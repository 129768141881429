import { Flowbite, useThemeMode } from 'flowbite-react';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import theme from '../flowbite-theme';
import { useFeatureToggle } from '../hooks/api/useFeatureToggle';

const FlowbiteWrapper: FC = function () {
  const dark = localStorage.getItem('theme') === 'dark';
  const navigate = useNavigate();
  const { data: featureToggles, isLoading } = useFeatureToggle();

  useEffect(() => {
    if (!isLoading && featureToggles?.data.cor184BackofficeMaintenance) {
      navigate('/pages/maintenance');
    }
  }, [featureToggles, isLoading, navigate]);

  return (
    <Flowbite theme={{ mode: dark ? 'dark' : 'light', theme }}>
      <PersistFlowbiteThemeToLocalStorage />
      <Outlet />
    </Flowbite>
  );
};

const PersistFlowbiteThemeToLocalStorage: FC = function () {
  const themeMode = useThemeMode();

  useEffect(() => {
    localStorage.setItem('theme', themeMode.mode);
  }, [themeMode]);

  return <></>;
};

export default FlowbiteWrapper;
