import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { CreateLocation, locationsApi } from 'cacao-sdk-typescript';

const createLocation = async (location: CreateLocation) => {
  try {
    const data = await locationsApi.locationPost(location);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error creating location:', error);
    throw new Error((error as any).data.message ?? 'Failed to create location');
  }
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (location: CreateLocation) => createLocation(location),
    onSuccess: () => {
      queryClient.invalidateQueries(['locations'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};
