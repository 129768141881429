import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { featureToggleApi } from 'cacao-sdk-typescript';

const fetchFeatureToggle = async () => {
  try {
    const data = await featureToggleApi.featureToggleGet();

    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching feature toggles:', error);
    throw new Error('Failed to fetch feature toggles');
  }
};

export const useFeatureToggle = () => {
  return useQuery({
    queryKey: ['featureToggles'],
    queryFn: fetchFeatureToggle,
  });
};
