import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { contributorsApi, UpdateContributor } from 'cacao-sdk-typescript';

const editContributor = async (
  contributorId: string,
  updatedData: UpdateContributor
) => {
  try {
    const data = await contributorsApi.contributorContributorIdPatch(
      contributorId,
      updatedData
    );

    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error editing contributor:', error);
    throw new Error('Failed to edit contributor');
  }
};

export const useEditContributor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      contributorId,
      updatedData,
    }: {
      contributorId: string;
      updatedData: UpdateContributor;
    }) => editContributor(contributorId, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(['contributors'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};
