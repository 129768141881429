import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { FetchProductParams } from '../../interfaces/product';
import { PaginatedProducts, productsApi } from 'cacao-sdk-typescript';

const fetchProducts = async ({
  page,
  pageSize,
  searchText,
}: FetchProductParams) => {
  const queryParams: Record<string, any> = {
    page,
    pageSize,
  };

  if (searchText && searchText.trim()) {
    queryParams.searchText = searchText.trim();
  }

  try {
    const data = await productsApi.productsGet(
      page,
      searchText?.trim() || undefined,
      pageSize
    );

    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching products:', error);
    throw new Error('Failed to fetch products');
  }
};

export const useProducts = ({
  page,
  pageSize,
  searchText = '',
}: FetchProductParams) => {
  return useQuery<PaginatedProducts>({
    queryKey: ['products', { page, pageSize, searchText: searchText.trim() }],
    queryFn: () =>
      fetchProducts({ page, pageSize, searchText: searchText.trim() }),
  });
};
