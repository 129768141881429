import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { FetchLocationParams } from '../../interfaces/location';
import { locationsApi, PaginatedLocations } from 'cacao-sdk-typescript';

const fetchLocations = async ({
  page,
  pageSize,
  searchText,
}: FetchLocationParams) => {
  const queryParams: Record<string, any> = {
    page,
    pageSize,
  };

  if (searchText && searchText.trim()) {
    queryParams.searchText = searchText.trim();
  }

  try {
    const data = await locationsApi.contributorLocationsGet(
      page,
      searchText?.trim() || undefined,
      pageSize
    );

    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching locations:', error);
    throw new Error('Failed to locations');
  }
};

export const useLocations = ({
  page,
  pageSize,
  searchText = '',
}: FetchLocationParams) => {
  return useQuery<PaginatedLocations>({
    queryKey: ['locations', { page, pageSize, searchText: searchText.trim() }],
    queryFn: () =>
      fetchLocations({ page, pageSize, searchText: searchText.trim() }),
  });
};
