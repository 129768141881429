import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { locationsApi, UpdateLocation } from 'cacao-sdk-typescript';

const editLocation = async (
  locationId: string,
  updatedData: UpdateLocation
) => {
  try {
    const data = await locationsApi.locationLocationIdPatch(
      locationId,
      updatedData
    );

    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error editing location:', error);
    throw new Error('Failed to edit location');
  }
};

export const useEditLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      locationId,
      updatedData,
    }: {
      locationId: string;
      updatedData: UpdateLocation;
    }) => editLocation(locationId, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(['locations'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};
