import {
  ContributorsGet200Response,
  contributorsApi,
} from 'cacao-sdk-typescript';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

interface FetchContributorsParams {
  page: number;
  pageSize: number;
  searchText?: string;
}

const fetchContributors = async ({
  page,
  pageSize,
  searchText,
}: FetchContributorsParams) => {
  try {
    const data = await contributorsApi.contributorsGet(
      page,
      pageSize,
      searchText?.trim() || undefined
    );
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching contributors:', error);
    throw new Error('Failed to fetch contributors');
  }
};

export const useContributors = ({
  page,
  pageSize,
  searchText = '',
}: FetchContributorsParams) => {
  return useQuery<ContributorsGet200Response>({
    queryKey: [
      'contributors',
      { page, pageSize, searchText: searchText.trim() },
    ],
    queryFn: () =>
      fetchContributors({ page, pageSize, searchText: searchText.trim() }),
  });
};
